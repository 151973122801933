.yj-index-wrapper{ background: #DFE1E4; overflow: hidden;
  .wrapper-header{ position: relative; height: 620px; background: url(https://qiniu.yunjilink.com/ca361d420d1a97f04088e231c31b9795.png) no-repeat; background-size: cover;
    .header-bg{ display: block; width: 100%;}
    .header-title{ position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); font-weight: 700; font-size: 48px; background: linear-gradient(180.31deg, #FDD891 18.01%, #DAB265 99.47%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; text-align: center;}
    .header-list{ width: 65%; display: flex; position: absolute; bottom: 20px; left: 50%; transform: translateX(-50%);
      .list-item{ cursor: pointer; text-decoration: none; flex: 1; min-width: 0; display: flex; justify-content: center; align-items: center; background: rgba(255,255,255,0.1); padding: 25px 0; transition: all .5s;
        &.a{ border-radius: 12px 0px 0px 12px }
        &.c{ border-radius: 0px 12px 12px 0px; }
        & + .list-item{ margin-left: 1px; }
        &:hover{ background: rgba(255,255,255, 0.2);}
        .item-icon{ display: block; width: 10%; }
        .item-title{ font-size: 16px; color: #909399; margin-left: 12px; text-align: center; }
      }
    }
  }
  .wrapper-box{
    .wrapper-container{ background-color: #fff; position: relative; padding: 120px 0;
      .container{ position: relative; padding: 0 10px; opacity: 0; animation: all .3s;
        &.active{ opacity: 1; }
        .container-header{ text-align: center; font-size: 36px; font-weight: 600; color: #000000;}
        .container-header2{ margin: 24px 0; font-weight: 600; font-size: 24px; color: #DAB265; text-align: center;}
        .container-content{ text-align: center; font-size: 18px; color: #606266; margin-top: 24px; 
          div{ margin-bottom: 10px; 
            span{ font-weight: 600; font-size: 24px; color: #000000;}
          }
        }
        .container-list{ display: flex; flex-wrap: wrap; margin-top: 24px; position: relative; 
          .list-item{ background: #FFFFFF; box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05); border-radius: 12px; transition: all .3s;
            &:hover{ transform: scale(1.1); box-shadow: 10px 0 20px 0 rgba(0, 0, 0, .2);}
          }
        }
      }
      &.register{ padding: 0;
        .register-list{ display: flex; justify-content: space-between;
          .list-item{ width: 49%; display: flex; align-items: center; justify-content: center; position: relative; margin: 20px 0; padding: 40px 0;  background: linear-gradient(116.04deg, #FFFDFB 33.59%, #FDF5EC 66.41%), #FFFFFF; border-radius: 12px; border: 1px solid #FCEDDB; overflow: hidden;
            .item-mask{ display: block; width: 35%; position: absolute; top: 0; left: 10px; right: 0; }
            .item-corner{ display: block; width: 100%; position: absolute; top: 0; }
            .item-icon{ display: block; width: 80px; height: 80px; }
            .item-content{ margin-left: 20px;
              .num{ font-size: 40px; font-weight: 700; color: #303133; }
              .content{ font-size: 16px; color: #606266; opacity: 0.8; margin-top: 6px; white-space: nowrap;}
            }
          }
        }
      }
      &.c1{ background: #f7fafd; }
      &.users{
        .container{
          .container-list{ justify-content: space-between;
            .list-item{ width: 23%; padding: 32px 0 90px 0; justify-content: space-around;
              .item-icon{ margin: 0 auto; margin-bottom: 32px; width: 120px; height: 120px; border-radius: 50%; background: #FDF7EC; display: flex; justify-content: center; align-items: center;
                .icon{ display: block; width: 60%; }
              }
              .item-title1{ font-weight: 600; font-size: 24px; text-align: center; color: #303133; }
              .item-title2{ line-height: 38px; font-weight: 400; font-size: 24.4286px; color: #D9D2C8; text-align: center;  }
            }
          }
        }
      }
      &.ficc{
        .container{
          .container-list{ flex-wrap: wrap;
            .ficc-item{ width: 25%; padding: 2% 2% 3%; transition: all .3s;
              &:nth-child(1){ background: #DAB265; }
              &:nth-child(2){ background: #FFC998; }
              &:nth-child(3){ background: #FFE0C2; }
              &:nth-child(4){ background: #FFF9E6; }
              &:nth-child(5){ background: #FFA082; }
              &:nth-child(6){ background: #D0DBFF; }
              &:nth-child(7){ background: #DDF7FF; }
              &:nth-child(8){ background: #FDFDFD; }
              .item-content{ display: flex; justify-content: space-between; align-items: center; 
                .content-title{ font-size: 36px; font-weight: 600; color: #303133; }
                .content-icon{ display: block; height: 56px; }
              }
              .item-title{ margin-top: 26px; font-size: 24px; color: #303133; opacity: 0.6;}
            }
          }
        }
      }
      &.equity{
        .container{
          .container-content{
            div{ letter-spacing: 1px; }
            .date{ font-size: 14px; color: #000;}
          }
          .container-swiper{ margin-top: 36px; overflow: hidden;
            &.swiper1{ display: block; height: 400px; } 
            &.swiper2{ display: none; height: 300px;
              .swiper-wrapper{
                .swiper-slide{
                  .slide-item{ width: 47.5%;
                    &.zxzq{
                      .item-content{
                        .icon1{ height: 24px; }
                      }
                    }
                    .item-content{
                      .icon1{ height: 24px; }
                      .num{ margin-top: 15px; font-size: 28px; }
                      .sub{ margin-top: 7px; font-size: 14px; }
                    }
                  }
                }
              }
            } 
            .swiper-wrapper{
              .swiper-slide{ display: flex; justify-content: space-between; 
                .slide-item{ height: 330px; position: relative; border: 1px solid #FFFFFF; width: 23.5%; background-color: #fff; border-radius: 12px; overflow: hidden;
                  .item-bg{ display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
                  .item-content{ padding: 26px; position: relative; 
                    .icon1{ display: block; height: 36px; }
                    .num{ margin-top: 78px; color: #004F9D; font-size: 36px; font-weight: 600; }
                    .sub{ margin-top: 14px; font-size: 16px; color: #909399; }
                    .icon2{ margin-top: 5px; display: block; height: 22px; }
                  } 
                  &.yfd{
                    .item-content{
                      .num{ color: #004F9D; }
                    }
                  }
                  &.hx{
                    .item-content{
                      .num{ color: #F84E55; }
                    }
                  }
                  &.zs{
                    .item-content{
                      .num{ color: #E55B14; }
                    }
                  }
                  &.st{
                    .item-content{
                      .num{ color: #563119; }
                    }
                  }
                  &.nf{
                    .item-content{
                      .num{ color: #0D328A; }
                    }
                  }
                  &.fg{
                    .item-content{
                      .num{ color: #FEBD19; }
                    }
                  }
                  &.htf{
                    .item-content{
                      .num{ color: #65451F; }
                    }
                  }
                  &.zxzq{
                    .item-content{
                      .icon1{ height: 28px;}
                      .num{ color: #F13D45; }
                    }
                  }
                }
              }
            }
            .swiper-pagination{
              .swiper-pagination-bullet{ width: 12px; height: 12px; border-radius: 50%; background: #C9D0D8; transition: all .3s; }
              .swiper-pagination-bullet-active{ background-color: #DAB265; width: 32px; border-radius: 100px; transition: all .3s;}
            }
          }
        }
      }
      &.magic{
        .container{
          .container-list{ text-align: center; flex-wrap: wrap; justify-content: space-between; margin-top: 30px; 
            .magic-item{ width: 32%; position: relative; background: #FFFFFF; box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05); backdrop-filter: blur(5px); border-radius: 12px; transition: all .3s; margin-bottom: 2%;
              &:hover{ transform: scale(1.1); box-shadow: 10px 0 20px 0 rgba(0, 0, 0, .2);}
              .item-content{
                .content-icon{ display: block; width: 15%; margin: 10px auto; margin-top: 32px;}
                .content-title{ font-weight: 600; font-size: 24px; color: #303133; }
                .content{ padding: 0 60px; color: #303133; font-size: 16px; margin-top: 12px; margin-bottom: 40px;}
              }
              &:nth-child(1){ background: #D1DEFC; }
              &:nth-child(2){ background: #FFAE95; }
              &:nth-child(3){ background: #DCF4D5; }
              &:nth-child(4){ background: #FFDEF3; }
              &:nth-child(5){ background: #E3F5FE; }
              &:nth-child(6){ background: #FBEBD1; }
            }
          }
        }
      }
      &.road{
        .container{
          .container-list{ justify-content: space-between; flex-wrap: wrap; margin-top: 30px; 
            .road-item{ width: 32%; padding: 37px 0; background: #FFFFFF; box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05); border-radius: 12px; margin: 2% 0 0 0; display: flex; justify-content: center; align-items: center; transition: all .3s;
              &:hover{ transform: scale(1.1); box-shadow: 10px 0 20px 0 rgba(0, 0, 0, .2);}
              .item-content{ 
                .content-icon{ display: block; height: 80px; margin: 0 auto;}
                .content-title{ margin-top: 24px; font-weight: 600; font-size: 24px; color: #303133; text-align: center;}
                .content-intro{ padding: 0 10px; margin-top: 16px; font-size: 16px; color: #606266; opacity: 0.8; text-align: center; }
              }
            }
          } 
        }
      }
      &.certification{
        .container{
          .container-list{ margin-top: 65px; z-index: 1; flex-wrap: wrap; justify-content: center;
            .item{ width: 23.5%; margin-bottom: 30px; margin-right: 1.5%;
              .item-icon{ height: 360px; position: relative; overflow: hidden; background-color: #fff; box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05); border-radius: 12px; display: flex; justify-content: center; align-items: center; transition: all .3s;
                &:hover{ transform: scale(1.1); box-shadow: 10px 0 20px 0 rgba(0, 0, 0, .2);}
                .icon{ display: block; max-width: 70%; 
                  &.s{ max-width: 90%;}
                }
              }
              .item-title{ margin-top: 25px; font-size: 16px; font-weight: 600; color: #303133; text-align: center; }
            }
          }
        }
      }
      &.cooperation{ background: #f7fafd;
        .container{
          .container-icon{ display: block; width: 100%; margin-top: 8px; 
            &.mobile{ display: none; }
          }
          .container-list{ display: block; margin-top: 70px;
            .cooperation-item{ display: flex;
              .item-title{ flex: 0 0 auto; width: 160px; height: 60px; display: flex; align-items: center; justify-content: center; background-color: #fff; border: 1px solid #FFFFFF; border-radius: 4px; box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);  color: #303133; font-weight: 600; font-size: 18px;
                &.item0{ background: linear-gradient(90deg, #FFFCF3 -2.19%, #FFFFFF 100%);; }
                &.item1{ background: linear-gradient(90deg, #FFF3F3 -2.19%, #FFFFFF 100%);}
                &.item2{ background: linear-gradient(90deg, #F3F5FF -2.19%, #FFFFFF 100%); }
                &.item3{ background: linear-gradient(90deg, #F5FAFF -2.19%, #FFFFFF 100%); }
              }
              .item-content{ margin-left: 12px; display: flex; flex-wrap: wrap;
                &.team{
                  .content{ border-radius: 4px; padding-right: 45px; position: relative; background-color: #fff; display: flex;
                    .tag{ text-align: center; padding: 2px 12px; height: 22px; font-size: 14px; font-weight: 600; background-color: #fff8f4; color: #e86649; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;  }
                    span{ margin: 15px 0 0 15px;}
                    .area{ display: flex; }
                  }
                }
                .content-item{ border-radius: 4px; margin: 0 12px 12px 0; background: #FFFFFF; box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1); width: 156px; height: 60px; display: flex; justify-content: center; align-items: center; overflow: hidden;
                  img{ height: 60px; display: block; }
                } 
                .content{ font-size: 16px; color: #303133; line-height: 1.6;
                  span{ font-size: 18px; color: #303133; font-weight: 600;}
                }
              }
            }
          }
        }
      }
      .container-bg{ position: absolute; bottom: 0; width: 100%;}
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1296px){
  .yj-index-wrapper{
    .wrapper-header{
      .header-list{ width: 90%; }
    }
  }
}
@media screen and (max-width: 768px){
  .yj-index-wrapper{
    .wrapper-header{ height: 400px;
      .header-title{ text-align: center; width: 334px; font-size: 28px;} 
      .header-list{ width: 90%; bottom: 6px;
        .list-item{ flex-direction: column; padding: 10px 0;
          &.c{ padding: 10px 5px ;}
          &:hover{ background: rgba(255,255,255,0.1); transform: scale(1); border-radius: 0;
            .item-title{ color: #909399; }
          }
          .item-icon{ width: 30px; }
          .item-title{ font-size: 12px; margin-left: 3px; margin-top: 5px;}
        }
      }
    }
    .wrapper-box{
      .wrapper-container{ padding: 30px 15px; 
        .container{
          .container-header{ font-size: 24px; }
          .container-content{ font-size: 15px; line-height: 1.8;
            div{ margin-bottom: 5px;
              span{ font-weight: 600; font-size: 14px; color: #303133; }
              &:nth-child(2){ display: inline;}
              &:nth-child(3){ display: inline;}
            }
          }
          .container-list{
            .list-item{ width: 48%; margin-right: 7px; margin-bottom: 7px;
              & + .list-item{ margin-right: 7px; }
               &:hover{ transform: scale(1); box-shadow: none;}
              .item-content{ padding: 0;
                .content-icon{ width: 80px; height: 80px; margin: 0 auto; margin-top: 40px; }
                .content-title{ margin-top: 16px; font-size: 16px;}
              }
              .item-title{ font-size: 14px; line-height: 0; margin-top: 15px;}
            }
          }
        }
        &.register{
          .container{
            .register-list{
              .item-mask{ display: block; width: 100%; position: absolute; top: 0; left: 10px; right: 0; }
              .item-corner{ display: block; width: 100%; position: absolute; top: 0; } 
              .list-item{ padding: 20px 0;  position: relative; flex-direction: column;
                .item-icon{ margin: 0 auto; width: 60px; height: 60px;}
                  .item-content{ margin-left: 0; margin-top: 10px;
                    .num{ font-size: 20px; text-align: center; }
                    .content{ font-size: 12px; text-align: center;}
                  }
              }
            }
          }
        }
        &.users{
          .container{
            .container-list{ justify-content: space-around;
              .list-item{ width: 47.5%; margin-right: 0; margin-bottom: 2%; padding: 0;
                & + .list-item{ margin-right: 0; }
                .item-icon{  width: 80px; height: 80px; margin: 0 auto; margin-top: 40px; }
                .item-title1{ margin-top: 16px; font-size: 16px;}
                .item-title2{ font-size: 14px; line-height: 0; margin-top: 15px; margin-bottom: 48px;}
              }
            }
          }
        }
        &.ficc{
          .container{
            .container-header2{ font-size: 18px; margin: 12px 0;}
            .container-content{ margin-top: 15px; 
              div{ display: inline; }
            }
            .container-list{
              .ficc-item{ padding: 0; width: 50%;
                .item-content{ padding: 0 15px; padding-top: 15px;
                  .content-title{ font-size: 24px;}
                  .content-icon{ width: 40px; height: 40px;}
                }
                .item-title{ margin-top: 0; font-size: 16px; padding: 0 15px; padding-bottom: 20px;}
              }
            }
          }
        }
        &.equity{
          .container{
            .container-header2{ font-size: 18px; margin: 12px 0;}
            .container-content{ margin-top: 15px; 
              div{ display: inline; }
              .date{ display: block; }
            }
            .container-swiper{
              &.swiper1{ display: none; }
              &.swiper2{ display: block; 
                .swiper-wrapper{
                  .swiper-slide{
                    .slide-item{ height: 220px; }
                  }
                }
              }
            }
          }
        }
        &.magic{
          .container{
            .container-header2{ font-size: 18px; margin: 12px 0; }
            .container-list{ margin-top: 15px; justify-content: space-around;
              .magic-item{ width: 47.5%; height: 180px; margin: 0; margin-bottom: 10px; margin-right: 0;
                &:hover{ transform: scale(1); box-shadow: none;}
                .item-content{
                  .content-icon{ width: 18%; }
                  .content-title{ font-size: 18px; }
                  .content{ padding: 0; font-size: 12px; padding: 0 10px; margin-bottom: 0; }
                }
              }
            }
          }
        }
        &.road{
          .container{
            .container-header2{ font-size: 18px; margin: 12px 0; }
            .container-list{ margin-top: 0; justify-content: space-around;
              .road-item{ padding: 0; height: 200px; width: 47.5%; margin: 0; margin-right: 0; margin-bottom: 10px; 
                &:hover{ transform: scale(1); box-shadow: none;}
                .item-content{
                  .content-icon{ height: 40px; }
                  .content-title{ font-size: 18px; margin-top: 12px;}
                  .content-intro{ font-size: 12px; padding: 0 20px; }
                }
              }
            }
          }
        }
        &.certification{
          .container{
            .container-list{ margin-top: 32px; flex-wrap: wrap; display: flex;
              .item{ width: 47.5%; margin-right: 2.5%; margin-bottom: 2.5%;
                & + .item{ margin-left: 0; }
                .item-icon{ height: 240px; 
                  &:hover{ transform: scale(1); box-shadow: none;}
                }
                .item-title{ margin-top: 16px; font-size: 12px; }
              }
            }
          }
        }
        &.cooperation{
          .container{
            .container-icon{ display: none;
              &.mobile{ display: block; }
            }
            .container-list{ 
              .cooperation-item{ display: inherit; margin-bottom: 15px; justify-content: space-between;
                .item-title{ padding: 9px 0; width: 100%;}
                .item-content{ margin-left: 0; margin-top: 2.5%; justify-content: space-between;
                  &.team{ display: block;
                    .content{ margin-top: 10px; display: flex; flex-wrap: wrap; justify-content: left; padding-right: 0;
                      & + .content{ margin-left: 0; }
                      span{ display: block; }
                      .area{ display: flex; flex-wrap: wrap; padding: 0 24px; padding-bottom: 20px;}
                    }
                  }
                  .content-item{ padding: 0; width: 48.8%; height: 60px; margin: 0; margin-bottom: 2.5%; 
                    img{ height: 60px; }
                  }
                  .content{ font-size: 15px;
                    span{ font-size: 17px; }
                  }
                } 
              }
            }
          }
        }
      }
    }
  }
}